.infoContainer {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.infoIcon {
  font-size: 18px;
  color: #555;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.infoIcon:hover {
  color: #007bff;
}

.tooltip {
  position: absolute;
  bottom: 50%;
  left: 50%;
  /* transform: translateX(-50%); */
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  line-height: 16px;
  font-size: 12px;
  /* white-space: nowrap; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
  visibility: visible;
  /* width: auto; */
  /* max-width: 300px; */
  min-width: 200px;
  height: auto;

}

.infoContainer:hover #tooltip {
  opacity: 1;
  visibility: visible;
}
